import React from 'react';
import styles from './index.module.less';
import Navigation from '@containers/Navigation';
import NameAndTexts from '@components/NameAndTexts';
import Sidebar from '@components/Sidebar';
import About from '@containers/About';
import Projects from '@containers/Projects';

function App() {
  return (
    <>
      <Navigation />

      <div className={styles.appContainer}>
        <NameAndTexts />

        <Sidebar />

        <About />

        <Projects />
      </div>
    </>
  );
}

export default App;
