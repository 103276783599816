const INODASH = {
  title: 'Inodash',
  type: 'Professional Team Project',
  status: 'Delivered Project',
  githubUrl: '',
  demoUrl1: 'https://www.inodash.com/',
  description:
    'Inodash help companies and teams to build validated products & services by collaborative workspaces which have structured steps and dynamic data flow.',
  skillsArr: [
    'React',
    'Redux',
    'React Router',
    'Figma',
    'Git',
    'Ant Design',
    'React DnD',
  ],
};

const STAALX = {
  title: 'Staalx',
  type: 'Professional Team Project',
  status: 'Work In Progress',
  githubUrl: '',
  demoUrl1: 'https://www.staalx.com/',
  description:
    'StaalX is an online marketplace platform specifically designed for buying and selling reinforcing bars (rebars). ',
  skillsArr: [
    'React',
    'Redux',
    'NextJS',
    'Figma',
    'Zeplin',
    'Git',
    'React Bootstrap',
  ],
};

const APPCART = {
  title: 'AppCart',
  type: 'Professional Team Project',
  status: 'Delivered Project',
  githubUrl: '',
  demoUrl1: 'https://app-cart.io/',
  description: 'A platform that unites investors and software developers.',
  skillsArr: ['React', 'TypeScript', 'React Router', 'Git', 'Adobe XD'],
};

const HALKAARZHESAP = {
  title: 'Halka Arz Hesaplama',
  type: 'Personal Project',
  status: 'MVP',
  githubUrl: '',
  demoUrl1: 'https://halkaarzhesap.com/',
  description: 'The easiest way to calculate an IPO!',
  skillsArr: ['React', 'React Router', 'Git', 'Ant Design', 'React DnD'],
};

const PROJETAKIPSISTEMI = {
  title: 'Proje Takip Sistemi',
  type: 'Graduation Project',
  status: 'MVP',
  githubUrl: '',
  demoUrl1: '',
  description:
    'With the Proje Takip Sistemi, they will be able to control and monitor all the data about the projects assigned by our teachers, which students are given, and what the project topic is, through a single system.',
  skillsArr: [
    'React',
    'Redux',
    'React Router',
    'Ant Design',
    'Git',
    'Axios',
    'NodeJS',
    'ExpressJS',
    'Mongoose',
    'pdf-lib',
    'Responsive Design',
  ],
};

const PRODUCTWATCHER = {
  title: 'Product Watcher',
  type: 'Professional Team Project',
  status: 'Delivered Project',
  githubUrl: '',
  demoUrl1: '',
  description:
    'It is an application made to follow the status of the projects and what stage they are in.',
  skillsArr: [
    'React Native',
    'Redux',
    'Expo',
    'React Navigation',
    'Git',
    'Zeplin',
  ],
};

const CEMDB = {
  title: 'Ce MDB',
  type: 'Bootcamp Project',
  status: 'MVP',
  githubUrl: 'https://github.com/ceyhungulbas/Ce-MDB',
  demoUrl1: 'https://ce-mdb.netlify.app/',
  description:
    'A website where you can find information and see ratings about the casts and movies.',
  skillsArr: ['HTML', 'CSS', 'JavaScript', 'Bootstrap', 'Git'],
};

const MADLIBZ = {
  title: 'MadLibz',
  type: 'Bootcamp Project',
  status: 'MVP',
  githubUrl: 'https://github.com/ceyhungulbas/madLibz',
  demoUrl1: 'https://madlibsgame.netlify.app/',
  description: 'Mad Libs is a phrasal template word game.',
  skillsArr: ['HTML', 'CSS', 'JavaScript', 'Bootstrap', 'Git'],
};

const MAKKOM = {
  title: 'MAK-KOM',
  type: 'Freelance Project',
  status: 'Delivered Project',
  githubUrl: 'https://github.com/ceyhungulbas/makkom-responsive',
  demoUrl1: 'https://ce-makkom.netlify.app/',
  description: 'Site and Workplace Management Company from Bursa.',
  skillsArr: ['HTML', 'CSS', 'Git', 'Responsive Design'],
};

const PERSONALTRAINER = {
  title: 'Diary of Personal Trainer',
  type: 'Bootcamp Project',
  status: 'MVP',
  githubUrl: 'https://github.com/ceyhungulbas/x-final-board-project',
  demoUrl1: 'https://cemupersonaltrainerboard.netlify.app/',
  description:
    'This is an online platform for preparing a weekly personal exercise program for clients.',
  skillsArr: ['React', 'React Bootstrap', 'Firebase', 'Git'],
};

const RANDOTRON = {
  title: 'Randotron',
  type: 'Personal Project',
  status: 'MVP',
  githubUrl: 'https://github.com/ceyhungulbas/randotron',
  demoUrl1: 'https://ce-randotron.netlify.app/',
  description: 'Random number generator.',
  skillsArr: ['HTML', 'CSS', 'JavaScript', 'Git', 'Responsive Design'],
};

const OTM = {
  title: 'O Tarz Mı?',
  type: 'Personal Project',
  status: 'MVP',
  githubUrl: 'https://github.com/ceyhungulbas/otarzmi',
  demoUrl1: 'https://cgotm-fan-art.netlify.app/',
  description:
    "Turkey's most listened podcast by Bengi, Bono, Temiz and Türküsev.",
  skillsArr: ['HTML', 'CSS', 'Git', 'Responsive Design'],
};

const GITHUBAPI = {
  title: 'Github API',
  type: 'Personal Project',
  status: 'MVP',
  githubUrl: 'https://github.com/ceyhungulbas/githubAPI',
  demoUrl1: 'https://github-api-ceyhungulbas.netlify.app/',
  description: 'Searching Github users by Github API',
  skillsArr: ['React', 'React Bootstrap', 'Git'],
};

const RAFFLE = {
  title: 'The Raffle',
  type: 'Personal Project',
  status: 'MVP',
  githubUrl: 'https://github.com/ceyhungulbas/theRaffle',
  demoUrl1: 'https://replit.com/@ceyhungulbas/theRaffle#main.py',
  description: 'Basic draw app, made by python.',
  skillsArr: ['Python'],
};

const MASTERMIND = {
  title: 'Mastermind',
  type: 'Personal Project',
  status: 'MVP',
  githubUrl: 'https://github.com/ceyhungulbas/masterMind',
  demoUrl1: 'https://replit.com/@ceyhungulbas/masterMind#main.py',
  description: 'Mastermind is a code-breaking game.',
  skillsArr: ['Python'],
};

const EXAMHUNTER = {
  title: 'Exam Hunter',
  type: 'Personal Project',
  status: 'MVP',
  githubUrl: 'https://github.com/ceyhungulbas/discordBot_examHunter',
  demoUrl1: '',
  description:
    "This bot's duty is helping to your exams! When you post your question as an image this bot going to react with options!",
  skillsArr: ['JavaScript'],
};

export const MY_PROJECTS = [
  INODASH,
  STAALX,
  APPCART,
  HALKAARZHESAP,
  PROJETAKIPSISTEMI,
  PRODUCTWATCHER,
  CEMDB,
  MADLIBZ,
  MAKKOM,
  PERSONALTRAINER,
  RANDOTRON,
  OTM,
  GITHUBAPI,
  RAFFLE,
  MASTERMIND,
  EXAMHUNTER,
];

export const SWIPER_BREAKPOINTS = {
  280: {
    slidesPerView: 1.05,
    spaceBetween: 5,
  },
  450: {
    slidesPerView: 1.2,
    spaceBetween: 10,
  },
  768: {
    slidesPerView: 1.2,
    spaceBetween: 15,
  },
  992: {
    slidesPerView: 2.1,
    spaceBetween: 15,
  },
  1200: {
    slidesPerView: 2.1,
    spaceBetween: 20,
  },
  1400: {
    slidesPerView: 2.5,
    spaceBetween: 30,
  },
};
