import { MenuOutlined, TwitterOutlined } from '@ant-design/icons';
import { Button, Drawer, Menu, Modal } from 'antd';
import React, { useState } from 'react';
import styles from './index.module.less';
import CeyhunsProfilePic from '@assets/images/profile_pic.jpeg';
import { HashLink } from 'react-router-hash-link';
import {
  GithubOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  MailOutlined,
} from '@ant-design/icons';
import NavigationIconForModal from '../../components/NavigationIconForModal';

export default function Navigation() {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onDrawerClose = () => {
    setDrawerVisible(false);
  };

  const onDrawerCloseAndShowModal = () => {
    setDrawerVisible(false);
    setIsContactModalOpen(true);
  };

  return (
    <>
      <Modal
        title="Contact"
        visible={isContactModalOpen}
        footer={null}
        onCancel={() => setIsContactModalOpen(false)}
        className={styles.contactModal}
      >
        <div className={styles.iconContainerWrapper}>
          <NavigationIconForModal
            span="gulbasceyhun@gmail.com"
            href="mailto: gulbasceyhun@gmail.com"
            IconPackage={MailOutlined}
          />
          <NavigationIconForModal
            span="linkedin.com/in/ceyhun-gulbas"
            href="https://www.linkedin.com/in/ceyhun-gulbas/"
            IconPackage={LinkedinOutlined}
          />

          <NavigationIconForModal
            span="github.com/ceyhungulbas"
            href="https://github.com/ceyhungulbas"
            IconPackage={GithubOutlined}
          />

          <NavigationIconForModal
            span="@CeyhunGulbas"
            href="https://twitter.com/CeyhunGulbas"
            IconPackage={TwitterOutlined}
          />

          <NavigationIconForModal
            span="@ceyhungulbas"
            href="https://www.instagram.com/ceyhungulbas/"
            IconPackage={InstagramOutlined}
          />
        </div>
      </Modal>

      <div>
        <Button
          className={styles.svgMenuButton}
          type="primary"
          shape="circle"
          icon={<MenuOutlined className={styles.svgMenu} />}
          onClick={showDrawer}
        />

        <Drawer
          placement="left"
          onClose={onDrawerClose}
          visible={drawerVisible}
          className={styles.customDrawer}
        >
          <div className={styles.imageWrapper}>
            <img src={CeyhunsProfilePic} alt="Ceyhun Gülbaş" />
          </div>

          <div className={styles.hashLinkForSmallScreens}>
            <HashLink smooth to="/#" onClick={onDrawerClose}>
              Home
            </HashLink>

            <HashLink smooth to="/#about" onClick={onDrawerClose}>
              About
            </HashLink>

            <HashLink smooth to="/#projects" onClick={onDrawerClose}>
              Projects
            </HashLink>

            <HashLink smooth to="/#contact" onClick={onDrawerCloseAndShowModal}>
              Contact
            </HashLink>

            <a
              href="https://drive.google.com/file/d/1F9UoSa9TKbtYHb8ajpaEL4p_ssxDxEHL/view?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              Resume
            </a>
          </div>
        </Drawer>
      </div>

      <Menu
        className={`${styles.menuForLargeScreen} ${styles.hashlinkNav}`}
        mode="horizontal"
        overflowedIndicator={<MenuOutlined className={styles.svgMenu} />}
      >
        <HashLink smooth to="/#">
          Home
        </HashLink>

        <HashLink smooth to="/#about">
          About
        </HashLink>

        <HashLink smooth to="/#projects">
          Projects
        </HashLink>

        <HashLink smooth to="/#" onClick={() => setIsContactModalOpen(true)}>
          Contact
        </HashLink>

        <a
          href="https://drive.google.com/file/d/1F9UoSa9TKbtYHb8ajpaEL4p_ssxDxEHL/view?usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
        >
          Resume
        </a>
      </Menu>
    </>
  );
}
