import React from 'react';
import styles from './index.module.less';
import ProjectCard from '@components/ProjectCard';
import { MY_PROJECTS, SWIPER_BREAKPOINTS } from './project';
import { Swiper, SwiperSlide } from 'swiper/react';

export default function Projects() {
  const swipers = MY_PROJECTS.map((project, index) => (
    <SwiperSlide key={`${project.title}_${index}`}>
      <ProjectCard
        title={project?.title}
        type={project?.type}
        status={project?.status}
        githubUrl={project?.githubUrl}
        demoUrl1={project?.demoUrl1}
        description={project?.description}
        skillsArr={project?.skillsArr}
      />
    </SwiperSlide>
  ));

  return (
    <section id="projects">
      <h1 className={styles.sectionTitle}>Projects</h1>

      <Swiper
        className={styles.projectsContainer}
        breakpoints={SWIPER_BREAKPOINTS}
      >
        {swipers}
      </Swiper>
    </section>
  );
}
