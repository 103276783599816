import React from 'react';
import CeyhunsProfilePic from '@assets/images/profile_pic.jpeg';
import styles from './index.module.less';
import Typical from 'react-typical';

export default function NameAndTexts() {
  return (
    <div className={styles.componentWrapper}>
      <div className={styles.imageWrapper}>
        <img src={CeyhunsProfilePic} alt="Ceyhun Gülbaş" />
      </div>

      <div className={styles.nameAndTextsWrapper}>
        <h1>Ceyhun Gülbaş</h1>

        <div className={styles.loopWrapper}>
          <Typical
            className={styles.loop}
            steps={[
              1500,
              'Front-End Developer',
              5000,
              'Nonquitter',
              5000,
              'Amateur Sim Racer',
              5000,
              'Challenge Driven',
              5000,
              'F1 Enthusiast',
              5000,
              'Resilient',
            ]}
            loop={Infinity}
          />
        </div>
      </div>
    </div>
  );
}
