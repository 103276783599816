import React from 'react';
import styles from './index.module.less';

export default function About() {
  return (
    <section id="about" className={styles.aboutWrapper}>
      <h1>About</h1>

      <p>
        As a frontend developer with experience in React, Redux, React Native,
        and NextJS, I am well-equipped to build and maintain high-performing and
        visually appealing web and mobile applications.
      </p>
      <p>
        I am highly skilled in utilizing the latest frontend technologies and
        frameworks to create user-friendly interfaces that are both responsive
        and engaging. In addition, I have a strong understanding of software
        development principles and am able to work collaboratively with
        cross-functional teams to deliver projects on time and within budget.
      </p>

      <p>
        I am a proactive and hardworking individual who is dedicated to staying
        current with the latest developments in frontend technology. I am also a
        team player who values cultural diversity and is able to work
        effectively with people from different backgrounds and cultures. I am
        confident that my technical skills and soft skills make me a valuable
        asset to any organization and I am excited about the opportunity to
        bring my expertise to you.
      </p>
    </section>
  );
}
