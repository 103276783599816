import React from 'react';
import styles from './index.module.less';
import {
  GithubOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  MailOutlined,
  TwitterOutlined,
} from '@ant-design/icons';

export default function Sidebar() {
  return (
    <div className={styles.sidebar}>
      <div className={styles.iconContainer}>
        <div>
          <a
            target="_blank"
            rel="noreferrer"
            href="mailto: gulbasceyhun@gmail.com"
          >
            <MailOutlined className={styles.iconSVG} />
          </a>
        </div>
      </div>

      <div className={styles.iconContainer}>
        <div>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/ceyhun-gulbas/"
          >
            <LinkedinOutlined className={styles.iconSVG} />
          </a>
        </div>
      </div>

      <div className={styles.iconContainer}>
        <div>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/ceyhungulbas"
          >
            <GithubOutlined className={styles.iconSVG} />
          </a>
        </div>
      </div>

      <div className={styles.iconContainer}>
        <div>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/CeyhunGulbas"
          >
            <TwitterOutlined className={styles.iconSVG} />
          </a>
        </div>
      </div>

      <div className={styles.iconContainer}>
        <div>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/ceyhungulbas/"
          >
            <InstagramOutlined className={styles.iconSVG} />
          </a>
        </div>
      </div>
    </div>
  );
}
