import styles from './index.module.less';
import React from 'react';
import PropTypes from 'prop-types';

export default function NavigationIconForModal({ href, span, IconPackage }) {
  return (
    <div className={styles.iconContainer}>
      <div>
        <a target="_blank" rel="noreferrer" href={href}>
          <IconPackage className={styles.iconSVG} />
          <span>{span}</span>
        </a>
      </div>
    </div>
  );
}

NavigationIconForModal.propTypes = {
  href: PropTypes.string,
  span: PropTypes.string,
  IconPackage: PropTypes.func,
};
