import React from 'react';
import styles from './index.module.less';
import PropTypes from 'prop-types';
import { GithubOutlined, GlobalOutlined } from '@ant-design/icons';

export default function ProjectCard({
  title,
  type,
  status,
  githubUrl,
  demoUrl1,
  description,
  skillsArr,
}) {
  return (
    <div className={styles.projectCard}>
      <div className={styles.projectCardTitle}>
        <h3>{title}</h3>
      </div>

      <div className={styles.projectCardSubTitle}>
        <h4>{type}</h4>
      </div>

      <div className={styles.projectCardSubTitle}>
        <h5>{status}</h5>
      </div>

      <div className={styles.projectCardLinks}>
        {githubUrl?.length ? (
          <a href={githubUrl} target="_blank" rel="noreferrer">
            <GithubOutlined />
          </a>
        ) : null}

        {demoUrl1?.length ? (
          <a href={demoUrl1} target="_blank" rel="noreferrer">
            <GlobalOutlined />
          </a>
        ) : null}
      </div>

      <div className={styles.projectCardDescriptionTitle}>
        <h4>Description</h4>
      </div>

      <div className={styles.projectCardDescription}>
        <p>{description}</p>
      </div>

      <div className={styles.projectCardSkillsTitle}>
        <h4>Skills Applied</h4>
      </div>

      <ul className={styles.projectCardSkillsList}>
        {skillsArr?.map((skill, index) => {
          return <li key={`${skill}_${index}`}>{skill}</li>;
        })}
      </ul>
    </div>
  );
}

ProjectCard.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  status: PropTypes.string,
  githubUrl: PropTypes.string,
  demoUrl1: PropTypes.string,
  description: PropTypes.string,
  skillsArr: PropTypes.array,
};
